import Loadable from 'react-loadable';
import LoadingCmpt from '../components/LoadingCmpt';
import { ENABLE_DIGITAL_MEDIA_REACT, ENABLE_ORDER_DETAIL_REACT, ENABLE_REDEEM_CODE_REFACTOR } from '../constants';

export const AccountHome = Loadable({
  loader: () => import('../containers/Dashboard'/* webpackChunkName: "Dashboard" */),
  loading: LoadingCmpt,
});

export const Profile = Loadable({
  loader: () => import('../containers/Profile'/* webpackChunkName: "Profile" */),
  loading: LoadingCmpt,
});

export const PdfView = Loadable({
  loader: () => import('../components/OrgManagement/PdfView'/* webpackChunkName: "PdfView" */),
  loading: LoadingCmpt,
});

export const ShippingAddresses = Loadable({
  loader: () => import('../containers/UserAddressManagementPage'/* webpackChunkName: "UserAddressManagementPage"*/),
  loading: LoadingCmpt,
});

export const InvitationPage = Loadable({
  loader: () => import('../containers/InvitationPage'/* webpackChunkName: "OrgInvitation" */),
  loading: LoadingCmpt,
});

export const PaymentMethods = Loadable({
  loader: () => import('../containers/PaymentMethods'/* webpackChunkName: "PaymentMethods" */),
  loading: LoadingCmpt,
});

export const OrderHistory = Loadable({
  loader: () => import('../containers/OrderHistory'/* webpackChunkName: "OrderHistory" */),
  loading: LoadingCmpt,
});

export const OrdersAndAutoship = Loadable({
  loader: () => import('../components/OrdersAndAutoship'/* webpackChunkName: "OrdersAndAutoship" */),
  loading: LoadingCmpt,
});

export const GiftCardBalance = Loadable({
  loader: () => import('../containers/GiftCardBalance'/* webpackChunkName: "GiftCardBalance" */),
  loading: LoadingCmpt,
});

export const OrgMgmt = Loadable({
  loader: () => import('../containers/OrgMgmt'/* webpackChunkName: "OrgMgmt" */),
  loading: LoadingCmpt,
});

export const OrderDetails = Loadable({
  loader: () => ENABLE_ORDER_DETAIL_REACT ? import('../containers/OrderDetailsRQ'/* webpackChunkName: "OrderDetailsRQ" */) : import('../containers/OrderDetails'/* webpackChunkName: "OrderDetails" */),
  loading: LoadingCmpt,
});

export const DigitalMedia  = Loadable({
  loader: () => ENABLE_DIGITAL_MEDIA_REACT ? import('../containers/DigitalMediaRQ'/* webpackChunkName: "DigitalMedia" */) :  import('../containers/DigitalMedia'/* webpackChunkName: "DigitalMedia" */),
  loading: LoadingCmpt,
});

export const Subscriptions = Loadable({
  loader: () => import('../containers/Subscriptions'/* webpackChunkName: "Subscriptions" */),
  loading: LoadingCmpt,
});

export const SubscriptionsContainer = Loadable({
  loader: () => import('../containers/SubscriptionsContainer'/* webpackChunkName: "Subscriptions" */),
  loading: LoadingCmpt,
});

export const AutoshipPages = Loadable({
  loader: () => import('../components/Autoship/AutoshipPages'/* webpackChunkName: "AutoshipPages" */),
  loading: LoadingCmpt,
});

export const GiftCards = Loadable({
  loader: () => import('../containers/GiftCards'/* webpackChunkName: "GiftCards" */),
  loading: LoadingCmpt,
});

export const RedeemCode = Loadable({
  loader: () => ENABLE_REDEEM_CODE_REFACTOR ? import('../containers/RedeemCodeRQ'/* webpackChunkName: "RedeemCodeRQ" */)  : import('../containers/RedeemCode'/* webpackChunkName: "RedeemCode" */),
  loading: LoadingCmpt,
});

export const OrgPages = Loadable({
  loader: () => import('../containers/org/OrgPages'/* webpackChunkName: "OrgPages" */),
  loading: LoadingCmpt
});

export const LoggedOut = Loadable({
  loader: () => import('../components/LoggedOut'/* webpackChunkName: "LoggedOut" */),
  loading: LoadingCmpt,
});

export const ModalManager = Loadable({
  loader: () => import('../containers/ModalManager'/* webpackChunkName: "Modal" */),
  loading: LoadingCmpt,
});

export const Modal = Loadable({
  loader: () => import('../components/Modal'/* webpackChunkName: "Modal" */),
  loading: LoadingCmpt,
});

export const Placeholder = Loadable({
  loader: () => import('../components/Placeholder'/* webpackChunkName: "Placeholder" */),
  loading: LoadingCmpt,
});

export const Confirmation = Loadable({
  loader: () => import('../components/Confirmation'/* webpackChunkName: "Confirmation" */),
  loading: LoadingCmpt,
});

export const MultipleDownloadsSelect = Loadable({
  loader: () => import('../components/MultipleDownloadsSelect'/* webpackChunkName: "MultipleDownloadsSelect" */),
  loading: LoadingCmpt,
});

export const EbookOptions = Loadable({
  loader: () => import('../components/EbookOptions'/* webpackChunkName: "EbookOptions" */),
  loading: LoadingCmpt,
});

export const EmailGiftCardForm = Loadable({
  loader: () => import('../components/EmailGiftCardForm'/* webpackChunkName: "EmailGiftCard" */),
  loading: LoadingCmpt,
});

export const PinManagementForm = Loadable({
  loader: () => import('../components/PinManagementForm'/* webpackChunkName: "PinManagementForm" */),
  loading: LoadingCmpt,
});

export const ExternalResourceRedirectPage = Loadable({
  loader: () => import('../components/ExternalResourceRedirectPage'/* webpackChunkName: "Redirect" */),
  loading: LoadingCmpt,
});

export const ManageAccess = Loadable({
  loader: () => import('../containers/ManageAccessContainer'/* webpackChunkName: "ManageAccess" */),
  loading: LoadingCmpt
});
