import { createLogic } from 'redux-logic';
import { stopSubmit, setSubmitFailed, reset, isSubmitting } from 'redux-form';
import {
  GET_SHIPPING_ADDRESSES_PENDING,
  ADD_USER_SHIPPING_ADDRESS_PENDING,
  DELETE_USER_SHIPPING_ADDRESS_CONFIRM,
  DELETE_USER_SHIPPING_ADDRESS_PENDING,
  UPDATE_USER_SHIPPING_ADDRESS_PENDING,
  UPDATE_ACCOUNT_SHIPPING_ADDRESS_PENDING,
  DELETE_ACCOUNT_SHIPPING_ADDRESS_CONFIRM,
  DELETE_ACCOUNT_SHIPPING_ADDRESS_PENDING,
  ADD_ACCOUNT_SHIPPING_ADDRESS_PENDING,
  STANDARDIZE_ADDRESS_PENDING,
} from '../constants';
import {
  getShippingAddressesSuccess,
  getShippingAddressesError,
  addUserShippingAddressSuccess,
  addUserShippingAddressError,
  toggleForm,
  toggleModal,
  triggerModal,
  deleteUserShippingAddressSuccess,
  deleteUserShippingAddressPending,
  updateUserShippingAddressSuccess,
  updateUserShippingAddressError,
  setBannerState,
  updateAccountShippingAddressSuccess,
  updateAccountShippingAddressError,
  addAddressBeingProcessed,
  removeAddressBeingProcessed,
  deleteAccountShippingAddressSuccess,
  deleteAccountShippingAddressPending,
  addAccountShippingAddressError,
  addAccountShippingAddressSuccess,
  updateAutoshipsWithShippingAddress,
} from '../actions/AppActions';

export const getShippingAddressesLogic = createLogic({
  type: GET_SHIPPING_ADDRESSES_PENDING,
  latest: true,
  process({ httpClient, getState }, dispatch, done) {
    httpClient(dispatch)
      .get('/shipping-addresses')
      .then((res) => {
        dispatch(getShippingAddressesSuccess(res.data));
        done();
      })
      .catch((error) => {
        dispatch(
          setBannerState({
            data: {
              type: 'error',
              message: getState()?.intl?.messages?.Errors?.Profile
                ?.Get_Shipping_Addresses_Error,
            },
          })
        );
        dispatch(getShippingAddressesError({ err: error }));
        done();
      });
  },
});

function formatStandardizedAddressPayload(address) {
  return {
    address: {
      line1: address?.line1,
      line2: address?.line2,
      city: address?.city,
      countrySubdivision: address?.countrySubdivision,
      postalCode: address?.postalCode,
      country: address?.countryCode,
    },
    sourceSystem: 'MLC',
  };
}

function compareAddresses(enteredAddress, standardizedAddress) {
  const addressProperties = [
    'line1',
    'line2',
    'city',
    'countrySubdivision',
    'postalCode',
    'countryCode',
  ];
  const enteredAddressString = addressProperties
    .map((prop) => enteredAddress?.[prop]?.trim())
    .filter(Boolean)
    .join('');
  const standardizedAddressString = addressProperties
    .map((prop) => standardizedAddress[prop]?.trim())
    .filter(Boolean)
    .join('');
  return enteredAddressString === standardizedAddressString;
}

export const standardizeAddressLogic = createLogic({
  type: STANDARDIZE_ADDRESS_PENDING,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { address, actions } = action.payload;
    httpClient(dispatch)
      .post('/standardize-address', formatStandardizedAddressPayload(address))
      .then((res) => {
        const standardizeResponse = res.data;
        const careOf = standardizeResponse.referenceData.careOf;
        const isIdenticalAddress = compareAddresses(
          address,
          standardizeResponse.address
        );
        if ((standardizeResponse.address && !isIdenticalAddress) && standardizeResponse.isOverridable) {
          // show select address modal
          actions?.onVerify((prevConfigs) => ({
            ...prevConfigs,
            showVerifyModal: true,
            suggestedAddress: {
              ...address,
              ...standardizeResponse.address,
              line1: careOf ? careOf : standardizeResponse.address.line1,
              line2: standardizeResponse.address.line2,
              isOverridable: false,
            },
            enteredAddress: {
              ...address,
              isOverridable: standardizeResponse.isOverridable,
            },
            onSubmit: actions.onDispatch,
          }));
          actions?.setSubmitting?.(false);
        } else if ((standardizeResponse.address && isIdenticalAddress)) {
          // dispatch address action
          dispatch(actions?.onDispatch);
        } else if (
          (standardizeResponse.address && !isIdenticalAddress)
          && !standardizeResponse.isOverridable
        ) {
          // show are you sure modal
          actions?.onVerify((prevConfigs) => ({
            ...prevConfigs,
            showVerifyModal: true,
            suggestedAddress: null,
            enteredAddress: {
              ...address,
              isOverridable: standardizeResponse.isOverridable,
            },
            onSubmit: actions.onDispatch,
          }));
          actions?.setSubmitting?.(false);
        } else if (!standardizeResponse.address && standardizeResponse.errorMessage) {
          // show error message
          actions?.onError?.();
        }
        done();
      })
      .catch((error) => {
        actions?.onError?.();
      });
  },
});

export const addUserShippingAddressLogic = createLogic({
  type: ADD_USER_SHIPPING_ADDRESS_PENDING,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done) {
    const { address, actions } = action.payload;
    actions?.setProcessingAction?.(true);
    httpClient(dispatch)
      .post('/shipping-addresses', address, {
        params: {
          overrideAddressNormalization: actions?.overrideAddress,
        },
      })
      .then((res) => {
        dispatch(addUserShippingAddressSuccess(res.data));
        actions?.setSubmitting?.(false);
        actions?.setProcessingAction?.(false);
        actions?.onClose?.();
        actions?.onCloseModal?.();
        done();
      })
      .catch((error) => {
        actions?.onCloseModal?.();
        const intlErrors = getState()?.intl?.messages?.Errors;
        const errorMessage =
          intlErrors?.ShippingAddresses?.Confirm_Address_Error;

        actions?.setProcessingAction?.(false);
        actions?.setSubmitting?.(false);
        dispatch(
          addUserShippingAddressError({
            message: errorMessage,
          })
        );
        done();
      });
  },
});

export const updateUserShippingAddressLogic = createLogic({
  type: UPDATE_USER_SHIPPING_ADDRESS_PENDING,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done) {
    const { address, actions } = action.payload;
    dispatch(addAddressBeingProcessed(address.id));
    actions?.setProcessingAction?.(true);
    httpClient(dispatch)
      .put('/shipping-addresses', address, {
        params: {
          overrideAddressNormalization: actions?.overrideAddress,
        },
      })
      .then((res) => {
        dispatch(
          updateUserShippingAddressSuccess({
            existingId: address.id,
            updatedShippingAddress: res.data,
          })
        );
        dispatch(
          updateAutoshipsWithShippingAddress({
            existingId: address.id,
            address: res.data,
          })
        );
        dispatch(removeAddressBeingProcessed(address.id));
        actions?.setSubmitting?.(false);
        actions?.setProcessingAction?.(false);
        actions?.onClose?.();
        actions?.onCloseModal?.();
        done();
      })
      .catch((error) => {
        actions?.setProcessingAction?.(false);
        actions?.onCloseModal?.();
        const { status } = error.response;
        const intlErrors = getState()?.intl?.messages?.Errors;
        const errorMessage =
          status === 406
            ? intlErrors?.ShippingAddresses?.Confirm_Address_Error
            : intlErrors?.ShippingAddresses?.Update_Address_Error;

        // show error in modal vs in banner
        if (actions?.onClose) {
          dispatch(
            updateUserShippingAddressError({
              message: errorMessage,
            })
          );
        } else {
          const bannerState = {
            data: {
              type: 'error',
              message: errorMessage,
            },
          };
          dispatch(setBannerState(bannerState));
        }

        dispatch(removeAddressBeingProcessed(address.id));
        actions?.setSubmitting?.(false);
        done();
      });
  },
});

export const deleteUserShippingAddressPendingLogic = createLogic({
  type: DELETE_USER_SHIPPING_ADDRESS_PENDING,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done) {
    const shippingAddressId = action?.payload;
    httpClient(dispatch)
      .delete(`/shipping-addresses/${shippingAddressId}`)
      .then(() => {
        dispatch(deleteUserShippingAddressSuccess(shippingAddressId));
        dispatch(removeAddressBeingProcessed(shippingAddressId));

        done();
      })
      .catch((error) => {
        dispatch(removeAddressBeingProcessed(shippingAddressId));
        const errorMessage = getState()?.intl?.messages?.Errors
          .ShippingAddresses.Delete_Address_Error;
        const bannerState = {
          data: {
            type: 'error',
            message: errorMessage,
          },
        };
        dispatch(setBannerState(bannerState));
        done();
      });
  },
});

export const deleteUserShippingAddressConfirmLogic = createLogic({
  type: DELETE_USER_SHIPPING_ADDRESS_CONFIRM,
  latest: true,
  processOptions: {
    dispatchMultiple: true,
  },
  process({ action }, dispatch) {
    const address = action.payload;
    const modalProps = {
      show: true,
      message: `Are you sure you want to delete the following address:
                ${action.payload.line1}?`,
      okLabel: 'Yes',
      okDataHook: 'deleteAddressYes.button',
      cancelLabel: 'No',
      cancelDataHook: 'deleteAddressNo.button',
      buttonOrder: 'rtl',
      okClick: () => {
        dispatch(addAddressBeingProcessed(address.id));
        dispatch(deleteUserShippingAddressPending(address.id));
        dispatch(toggleModal());
      },
      cancelClick: () => {
        dispatch(toggleModal());
      },
    };
    dispatch(triggerModal(modalProps));
  },
});

export const updateAccountShippingAddressLogic = createLogic({
  type: UPDATE_ACCOUNT_SHIPPING_ADDRESS_PENDING,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done) {
    const {
      organizationId,
      billingAccountId,
      address,
      actions,
    } = action.payload;
    dispatch(addAddressBeingProcessed(address.id));
    actions?.setProcessingAction?.(true);
    httpClient(dispatch)
      .put(
        `/organizations/${organizationId}/billing-accounts/${billingAccountId}/addresses`,
        address,
        {
          params: {
            overrideAddressNormalization: actions?.overrideAddress,
          },
        }
      )
      .then((res) => {
        dispatch(
          updateAccountShippingAddressSuccess({
            organizationId,
            billingAccountId,
            existingId: address.id,
            updatedShippingAddress: res.data,
          })
        );

        dispatch(
          updateAutoshipsWithShippingAddress({
            address: res.data,
            existingId: address.id,
          })
        );

        dispatch(removeAddressBeingProcessed(address.id));
        actions?.setSubmitting?.(false);
        actions?.setProcessingAction?.(false);
        actions?.onClose?.();
        actions?.onCloseModal?.();
        done();
      })
      .catch((error) => {
        actions?.setProcessingAction?.(false);
        actions?.onCloseModal?.();
        const { status } = error.response;
        const intlErrors = getState()?.intl?.messages?.Errors;
        let errorMessage = '';
        if (status === 404) {
          errorMessage = intlErrors?.ShippingAddresses?.Confirm_Address_Error;
        } else if (status === 406) {
          errorMessage =
            intlErrors?.ShippingAddresses?.Missing_Address_Fields_Error;
        } else {
          errorMessage = intlErrors?.ShippingAddresses?.Update_Address_Error;
        }

        // show error in modal vs in banner
        if (actions?.onClose) {
          dispatch(
            updateAccountShippingAddressError({
              message: errorMessage,
            })
          );
        } else {
          const bannerState = {
            data: {
              type: 'error',
              message: errorMessage,
            },
          };
          dispatch(setBannerState(bannerState));
        }

        dispatch(removeAddressBeingProcessed(address.id));
        actions?.setSubmitting?.(false);
        done();
      });
  },
});

export const addAccountShippingAddressLogic = createLogic({
  type: ADD_ACCOUNT_SHIPPING_ADDRESS_PENDING,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done) {
    const {
      address,
      actions,
      organizationId,
      billingAccountId,
    } = action.payload;
    actions?.setProcessingAction?.(true);
    httpClient(dispatch)
      .post(
        `/organizations/${organizationId}/billing-accounts/${billingAccountId}/addresses`,
        address,
        {
          params: {
            overrideAddressNormalization: actions?.overrideAddress,
          },
        }
      )
      .then((res) => {
        dispatch(
          addAccountShippingAddressSuccess({
            organizationId,
            billingAccountId,
            address: res.data,
          })
        );
        actions?.setSubmitting?.(false);
        actions?.setProcessingAction?.(false);
        actions?.onClose?.();
        actions?.onCloseModal?.();
        done();
      })
      .catch((error) => {
        actions?.onCloseModal?.();
        const intlErrors = getState()?.intl?.messages?.Errors;
        const errorMessage =
          intlErrors?.ShippingAddresses?.Confirm_Address_Error;

        dispatch(
          addAccountShippingAddressError({
            message: errorMessage,
          })
        );
        actions?.setSubmitting?.(false);
        actions?.setProcessingAction?.(false);
        done();
      });
  },
});

export const deleteAccountShippingAddressConfirmLogic = createLogic({
  type: DELETE_ACCOUNT_SHIPPING_ADDRESS_CONFIRM,
  latest: true,
  processOptions: {
    dispatchMultiple: true,
  },
  process({ action }, dispatch) {
    const {
      organizationId,
      billingAccountId,
      addressId,
      line1,
    } = action.payload;
    const payload = {
      organizationId,
      billingAccountId,
      addressId,
    };
    const modalProps = {
      show: true,
      message: `Are you sure you want to delete the following address:
                ${line1}?`,
      okLabel: 'Yes',
      okDataHook: 'deleteAddressYes.button',
      cancelLabel: 'No',
      cancelDataHook: 'deleteAddressNo.button',
      buttonOrder: 'rtl',
      okClick: () => {
        dispatch(addAddressBeingProcessed(addressId));
        dispatch(deleteAccountShippingAddressPending(payload));
        dispatch(toggleModal());
      },
      cancelClick: () => {
        dispatch(toggleModal());
      },
    };
    dispatch(toggleForm(null));
    dispatch(triggerModal(modalProps));
  },
});

export const deleteAccountShippingAddressPendingLogic = createLogic({
  type: DELETE_ACCOUNT_SHIPPING_ADDRESS_PENDING,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done) {
    const { organizationId, billingAccountId, addressId } = action.payload;
    const payload = {
      organizationId,
      billingAccountId,
      addressId,
    };
    httpClient(dispatch)
      .delete(
        `/organizations/${organizationId}/billing-accounts/${billingAccountId}/addresses/${addressId}`
      )
      .then(() => {
        dispatch(deleteAccountShippingAddressSuccess(payload));
        dispatch(removeAddressBeingProcessed(addressId));
        done();
      })
      .catch((error) => {
        dispatch(removeAddressBeingProcessed(addressId));
        const errorMessage = getState()?.intl?.messages?.Errors
          .ShippingAddresses.Delete_Address_Error;
        const bannerState = {
          data: {
            type: 'error',
            message: errorMessage,
          },
        };
        dispatch(setBannerState(bannerState));
        done();
      });
  },
});

export default [
  standardizeAddressLogic,
  getShippingAddressesLogic,
  addUserShippingAddressLogic,
  updateUserShippingAddressLogic,
  deleteUserShippingAddressConfirmLogic,
  deleteUserShippingAddressPendingLogic,
  updateAccountShippingAddressLogic,
  deleteAccountShippingAddressPendingLogic,
  deleteAccountShippingAddressConfirmLogic,
  addAccountShippingAddressLogic,
];
